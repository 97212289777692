exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-brunch-menu-js": () => import("./../../../src/pages/brunch-menu.js" /* webpackChunkName: "component---src-pages-brunch-menu-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dessert-menu-js": () => import("./../../../src/pages/dessert-menu.js" /* webpackChunkName: "component---src-pages-dessert-menu-js" */),
  "component---src-pages-dinner-menu-js": () => import("./../../../src/pages/dinner-menu.js" /* webpackChunkName: "component---src-pages-dinner-menu-js" */),
  "component---src-pages-drinks-menu-js": () => import("./../../../src/pages/drinks-menu.js" /* webpackChunkName: "component---src-pages-drinks-menu-js" */),
  "component---src-pages-happy-hour-menu-js": () => import("./../../../src/pages/happy-hour-menu.js" /* webpackChunkName: "component---src-pages-happy-hour-menu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lunch-menu-js": () => import("./../../../src/pages/lunch-menu.js" /* webpackChunkName: "component---src-pages-lunch-menu-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-order-online-js": () => import("./../../../src/pages/order-online.js" /* webpackChunkName: "component---src-pages-order-online-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */)
}


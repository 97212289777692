import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { MainWrapper, StyledLink } from "components"

const ResInfoWrapper = styled.div`
  padding: 80px 0 30px 0;
  background-color: var(--whiteChalk);

  .custom-ot-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid rgb(218, 55, 67);
    transition: 0.4s box-shadow ease-in-out;
  }
`

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 25px;
    font-family: var(--fontSecondary);
    padding-bottom: 20px;
  }

  .opening {
    > div {
      padding: 20px 0;
    }

    h5 {
      padding-bottom: 10px;
    }
    p {
      font-size: 14px;
      padding-bottom: 25px;
    }
    .bottom {
      padding-bottom: 0px;
    }
  }

  .social {
    > h1 {
      padding-bottom: 20px;
    }

    .pad {
      padding-top: 15px;
    }

    @media (max-width: 992px) {
      padding-top: 60px;
    }
  }
`

const ContactInfo = styled.div`
  a {
    color: var(--black);
  }

  > div {
    padding: 5px 0;
  }

  span {
    padding-left: 10px;
    font-size: 13px;
  }
`

const ResInfo = () => {
  return (
    <ResInfoWrapper>
      <MainWrapper>
        <MainContent>
          <div className="opening">
            <h1 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease">
              Hours of Operation
            </h1>
            <div>
              <h5
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
              >
                Monday - Sunday
              </h5>
              <p
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
              >
                11:00am - 9:00pm
              </p>

              {/* <h5
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
              >
                MON - THUR
              </h5>
              <p
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-easing="ease"
                className="bottom"
              >
                11:00am - 9:00pm
              </p> */}
            </div>
            <StyledLink
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease"
              backgroundColor="var(--primaryColor)"
              to="/reservation"
            >
              Make a Reservation
            </StyledLink>
          </div>
          <div className="social">
            <h1 data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease">
              Contact
            </h1>
            <ContactInfo>
              <div
                data-sal="slide-up"
                data-sal-delay="350"
                data-sal-easing="ease"
              >
                <StaticImage
                  src="../../images/mail.png"
                  alt="Place"
                  quality="100"
                  height={20}
                  placeholder="blurred"
                />
                <a href="admin@spruzzopalisades.com">
                  {" "}
                  <span
                    data-sal="slide-up"
                    data-sal-delay="350"
                    data-sal-easing="ease"
                  >
                    admin@spruzzopalisades.com
                  </span>
                </a>
              </div>
              <div
                data-sal="slide-up"
                data-sal-delay="350"
                data-sal-easing="ease"
              >
                <StaticImage
                  src="../../images/phone.png"
                  alt="Place"
                  quality="100"
                  height={18}
                  placeholder="blurred"
                />
                <a href="tel: (424) 426-5900">
                  <span
                    data-sal="slide-up"
                    data-sal-delay="350"
                    data-sal-easing="ease"
                  >
                    (424) 426-5900
                  </span>
                </a>
              </div>
              <div
                data-sal="slide-up"
                data-sal-delay="350"
                data-sal-easing="ease"
              >
                <StaticImage
                  src="../../images/place.png"
                  alt="Place"
                  quality="100"
                  placeholder="blurred"
                  height={20}
                />

                <span
                  data-sal="slide-up"
                  data-sal-delay="350"
                  data-sal-easing="ease"
                >
                  538 Palisades Dr, Los Angeles, CA 90272
                </span>
              </div>
              <div className="pad">
                <a
                  href="https://www.instagram.com/spruzzopalisades/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span
                    data-sal="slide-up"
                    data-sal-delay="350"
                    data-sal-easing="ease"
                  >
                    <StaticImage
                      src="../../images/instagram.png"
                      alt="Place"
                      quality="100"
                      height={24}
                      placeholder="blurred"
                    />
                  </span>
                </a>
              </div>
            </ContactInfo>
          </div>
        </MainContent>
      </MainWrapper>
    </ResInfoWrapper>
  )
}

export default ResInfo

import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const LogoWrapper = styled.div``

const Logo = () => {
  return (
    <Link to="/">
      <LogoWrapper>
        <StaticImage
          src="../../images/logo.png"
          alt="Logo"
          height={55}
          placeholder="blurred"
          quality={90}
        />
      </LogoWrapper>
    </Link>
  )
}

export default Logo

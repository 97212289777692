import React from "react"
import styled from "styled-components"

const PageWrap = styled.div`
  position: relative;
  min-height: 367px;

  .gatsby-image-wrapper {
    width: inherit;
    height: inherit;
  }
`
const BackImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`

const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  z-index: 101;

  h1 {
    padding-top: 30px;
    z-index: 12;
    color: var(--white);
    font-size: 35px;
    font-family: "Oswald", sans-serif;
    letter-spacing: 2px;

    @media (max-width: 992px) {
      padding-top: 20px;
    }
  }
`

export const Overlay = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: -webkit-linear-gradient(
    top,
    rgb(76 73 73 / 51%) 0%,
    rgb(8 8 8 / 46%) 30%,
    rgb(45 45 45 / 45%) 100%
  );
  z-index: 11;
`

export const PageHeader = ({ title, children }) => {
  return (
    <PageWrap>
      <Overlay />
      <BackImage>{children}</BackImage>
      <Title>
        <h1 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
          {title}
        </h1>
      </Title>
    </PageWrap>
  )
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

const ThumbGrid = ({ images, handleOpen }) => {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 560: 2, 992: 3 }}>
      <Masonry className="thumbs">
        {images.map((image, i) => (
          <div className="image" key={i}>
            <div onClick={handleOpen(i)} className="previewButton show-image">
              <GatsbyImage image={getImage(image.node)} alt="Gallery" />
            </div>
          </div>
        ))}
      </Masonry>
    </ResponsiveMasonry>
  )
}

export { ThumbGrid }

import React from "react"

import Nav from "./Nav"
import Footer from "./Footer"
import ResInfo from "./ResInfo"

export const Layout = ({ children }) => {
  return (
    <>
      <Nav />
      {children}
      <ResInfo />
      <Footer />
    </>
  )
}

import React, { useState } from "react"
import styled from "styled-components"

import { StyledLink, SimpleButton } from "components"

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0 0 50px;

  @media (max-width: 992px) {
    margin: 8px 0 0 0;
    order: 1;
    > a > svg {
      margin-top: -4px;
      padding-top: 2px;
    }
  }
`

const Hamburger = styled.div`
  background-color: var(--black);
  width: 17px;
  height: 2px;
  transition: all 0.3s linear;
  position: relative;
  align-self: center;
  transform: ${props =>
    props.open ? "rotate(45deg) translate(-3px,-3px)" : "inherit"};

  ::before {
    width: 17px;
    height: 2px;
    background-color: var(--black);
    content: "";
    position: absolute;
    transition: var(--defaultTransition);
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-8px, 0px)" : "rotate(0deg)"};
    top: -8px;
  }
`

const Toggle = styled.div`
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: none;
  background: var(--cornFlowerBlue);
  border-radius: 50%;

  @media (max-width: 992px) {
    display: block;
    padding: 20px 6px 5px 10px;
    ::focus {
      background: transparent;
    }
  }
`

const MenuItem = styled.div`
  display: flex;
  list-style: none;
  transition: all 0.3s ease 0s;

  .item-content {
    display: flex;
    justify-content: center;

    @media (max-width: 992px) {
      padding: 30px;
      display: block;
    }
  }

  .gift {
    padding-left: 20px;

    @media (max-width: 992px) {
      padding-left: 0;
    }
  }

  @media (max-width: 992px) {
    display: ${({ open }) => (open ? "block" : "none")};
    position: absolute;
    background: var(--black);
    width: calc(100% - 30px);
    border-radius: 30px;
    top: 95px;
    left: 0;
    padding: 28px;
    margin: 15px;
  }
`

const OrderButton = styled.div`
  min-width: 125px;
  padding-right: 10px;
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
`

const Menu = () => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <MenuWrapper>
      <OrderButton>
        <StyledLink backgroundColor="var(--primaryColor)" to="/order-online">
          Order Online
        </StyledLink>
      </OrderButton>

      <Toggle onClick={() => setNavbarOpen(!navbarOpen)}>
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      <MenuItem open={navbarOpen}>
        <ul className="item-content">
          <li>
            <StyledLink to="/">Home</StyledLink>
          </li>
          <li>
            <StyledLink to="/menu">Menu</StyledLink>
          </li>
          <li>
            <StyledLink to="/gallery">Gallery</StyledLink>
          </li>
          <li>
            <StyledLink to="/contact-us">Contact Us</StyledLink>
          </li>
          <li>
            <StyledLink backgroundColor="transparent" to="/catering">
              Catering
            </StyledLink>
          </li>
          <li>
            <StyledLink backgroundColor="transparent" to="/reservation">
              Reservations
            </StyledLink>
          </li>

          <li>
            <SimpleButton
              background="transparent"
              rel="noopener noreferrer"
              target="_blank"
              padding="10px 20px"
              href="https://www.toasttab.com/spruzzopalisades/giftcards?utmCampaign=onlineOrdering"
            >
              Gift Cards
            </SimpleButton>
          </li>
          <li>
            <StyledLink
              backgroundColor="var(--primaryColor)"
              to="/order-online"
            >
              Order Online
            </StyledLink>
          </li>
        </ul>
      </MenuItem>
    </MenuWrapper>
  )
}

export default Menu

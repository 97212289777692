import React, { useEffect } from "react"
import styled from "styled-components"

import { MainWrapper } from "components"
import Logo from "./Logo"
import Menu from "./Menu"

const NavBar = styled.div`
  transition: 0.3s;

  .scroll {
    display: inherit;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgb(255 255 255);
    border: 1px solid rgb(0 0 0 / 30%);

    .scroll-mar {
      margin: 5px 0;
      transition: 0.3s;
    }
    .item-content {
      a {
        color: var(--black);

        &:hover {
          color: var(--secondaryColor);
        }

        @media (max-width: 992px) {
          color: var(--white);
        }
      }
    }
  }
`

const NavWrapper = styled.nav`
  width: 100%;
  position: fixed;
  z-index: 999;
  /* backdrop-filter: saturate(180%) blur(20px);
  background-color: rgb(213 206 206 / 55%); */
`

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  a {
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`

const Nav = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      //Nacbar Color on Scroll
      window.onscroll = () => {
        const scrollNav = document.querySelector(".head")

        const scrollMe = window.scrollY
        if (scrollMe >= 50) {
          scrollNav.classList.add("scroll")
        } else {
          scrollNav.classList.remove("scroll")
        }
      }
    }
  }, [])

  return (
    <NavBar>
      <NavWrapper className="head">
        <MainWrapper>
          <NavContent className="scroll-mar">
            <Logo />
            <Menu />
          </NavContent>
        </MainWrapper>
      </NavWrapper>
    </NavBar>
  )
}

export default Nav

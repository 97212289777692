import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  :root {
    /* ------------------------- color ------------------------- */
    --primaryColor: #186f99;
    --secondaryColor: #0c88c4;
    --walnut: #743c1b;
    --zombie: #4f4f4f;
    --whiteChalk: #F6F4F1;
    --white: #fff;
    --black: #000;

   /* ------------------------- Font------------------------- */
    --fontPrimary: 'Lato', sans-serif;
    --fontSecondary: 'Oswald', sans-serif;

    /* ------------------------- box shadow & border ------------------------- */
    --navbarBoxShadow: 0px 2px 12px #0000001D;
    --imageBorderRadius: 20px;

    /* ------------------------- font size ------------------------- */
    /*  common font size  */
    --commonTitle: 20px;

     /* ------------------------- font weight ------------------------- */
    --normal: 400;
    --semiBold: 400;
    --bold: 500;
     /* ------------------------- transition ------------------------- */
     --defaultTransition: 0.3s;
  }
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body{
    font-family: var(--fontPrimary);
    color: var(--black);
    font-weight: var(--normal);
    line-height: 1;
  }
    ol, ul {
    list-style: none;
  }
  a {
    text-decoration: none;
    display: inline-block;
  }
  .show-image {
    overflow: hidden;
    cursor: zoom-in;
    border-radius:20px;
  }
  .show-image img {
    transition: all 1.5s ease!important;
    border-radius:20px;
  }
  .gallery {
    width: auto;
    margin: 50px auto 0px;
    display: table;
    max-width: 1080px;
  }
  .show-image:hover img {
    transform: scale(1.2);
    border-radius:20px;
  }

  .thumbs {
    .image {
        padding: 10px;
    }
  }

  .custom-ot-wrapper {
    position: unset;
    border: 1px solid rgb(0 0 0 / 30%);
  }

  .order-button {
    position: fixed;
    height: 54px;
    left: 35px;
    bottom:80px;
    z-index: 9999;
    left: 35px;
    z-index: 9999;
  }
`

import React, { Fragment } from "react"
import { GlobalStyles } from "components"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import "react-opentable/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"

//global styles for whole website
export const wrapPageElement = ({ element }) => (
  <Fragment>
    <GlobalStyles />
    {element}
  </Fragment>
)
